import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>OSRS Goals</h1>
        <p>For support, suggestions, and ideas, email us at </p>
        <a
          href="mailto:scapegoals@gmail.com"
          style={{ color: "#ffff00", textDecorationColor: "#ffff00" }}
        >
          <span
            style={{
              fontFamily: "monospace",
              color: "#ffff00",
              textDecorationColor: "#ffff00",
            }}
          >
            scapegoals@gmail.com
          </span>
        </a>
      </header>
      <footer>
        <p
          style={{
            color: "white",
            position: "absolute",
            left: 16,
            bottom: 8,
            fontSize: 8,
          }}
        >
          © {new Date().getFullYear()} OSRSLY
        </p>
      </footer>
      {/* add copyright year */}
    </div>
  );
}

export default App;
